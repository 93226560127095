import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import styled from "@emotion/styled";
import {css} from "@emotion/core";
import Container from "react-bootstrap/Container";
import { Link } from "gatsby";
import { faHeart } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const HeForSheSection6Background = styled.div`
  background-color: ${props => props.theme.colors.accent_yellow70};
  color: ${props => props.theme.colors.gray_dark};
  font: 20px/28px Roboto;
  position:relative;
  @media(max-width:767px) {
    min-height: 0;
    overflow:hidden;
  }
  @media(min-width:1200px){
      height: 800px;
  }
  @media(min-width:1600px){
      height: 1000px;
  }
`

const HeForSheSection6Title = styled.div`
  font: 58px/62px Abril Fatface;
  min-height: 132px;
  color: ${props => props.theme.colors.gray_dark};
  @media(max-width:1199px){
    font: 56px/60px Abril Fatface;
  }
  @media(min-width:1200px){
    margin-bottom:35px;
  }
  @media(min-width:1024px) {
    margin-top:71px;
  }
  @media(max-width:767px){
    font: 2.5rem/3rem Abril Fatface;
    margin-bottom:17.5px;
  }
`

const SectionItem = styled.div`
  margin-bottom:100px;
  max-width:100%;
  display: inline-block;
  @media(max-width:767px){
    margin-top:0;
    margin-bottom:0px;
  }
`

const FAHeart = styled(FontAwesomeIcon)`
  font-size:1rem;
  color: ${props => props.theme.colors.gray_dark};
`

const ButtonFill6 = styled(Button)`
  height: 60px;
  color: ${props => props.theme.buttons.primary_button_violet.normal.color} !important;
  background-color: ${props => props.theme.buttons.primary_button_violet.normal.background_color} !important;
  border-color: ${props => props.theme.buttons.primary_button_violet.normal.border_color} !important;
  font: 22px/22px Roboto;
  transition: 0.25s ease-out;
  &:hover {
    color: ${props => props.theme.buttons.primary_button_violet.hovered.color} !important;
    background-color: ${props => props.theme.buttons.primary_button_violet.hovered.background_color} !important;
    border-color: ${props => props.theme.buttons.primary_button_violet.hovered.border_color} !important;
    box-shadow: 0px 3px 20px ${props => props.theme.buttons.primary_button_violet.hovered.shadow} !important;
    @media(min-width:1199px){
      margin-top:-3px;
      margin-bottom:3px;
    }
  }
  &:focus, &.focus, &:active, &.active {
    color: ${props => props.theme.buttons.primary_button_violet.pressed.color} !important;
    background-color: ${props => props.theme.buttons.primary_button_violet.pressed.background_color} !important;
    border-color: ${props => props.theme.buttons.primary_button_violet.pressed.border_color} !important;
    box-shadow: none !important;
    @media(min-width:1199px){
      margin-top:-3px;
      margin-bottom:3px;
    }
  }
  @media(max-width:921px){
    width:100%;
    margin-bottom:20px;
  }
  @media(max-width:767px){
    width:100%;
  }
`
const ButtonOutline6 = styled(Button)`
  height: 60px;
  color: ${props => props.theme.buttons.secondary_big_button_violet.normal.color} !important;
  background-color: transparent !important;
  border-color: ${props => props.theme.buttons.secondary_big_button_violet.normal.border_color} !important;
  font: 22px/22px Roboto;
  transition: 0.25s ease-out;
  &:hover {
    color: ${props => props.theme.buttons.secondary_big_button_violet.hovered.color} !important;
    background-color: ${props => props.theme.buttons.primary_button_violet.pressed.background_color} !important;
    border-color: ${props => props.theme.buttons.secondary_big_button_violet.hovered.border_color} !important;
    box-shadow: 0px 3px 20px ${props => props.theme.buttons.secondary_big_button_violet.normal.shadow} !important;
    @media(min-width:1199px){
      margin-top:-3px;
      margin-bottom:3px;
    }
  }
  &:focus, &.focus, &:active, &.active {
    color: ${props => props.theme.buttons.secondary_big_button_violet.pressed.color} !important;
    background-color: ${props => props.theme.buttons.primary_button_violet.pressed.background_color} !important;
    border-color: ${props => props.theme.buttons.secondary_big_button_violet.pressed.border_color} !important;
    box-shadow: none !important;
    @media(min-width:1199px){
      margin-top:-3px;
      margin-bottom:3px;
    }
  }
  @media(min-width:922px){
    margin-left:25px;
  }
  @media(max-width:921px){
    width:100%;
  }
  @media(max-width:767px){
    width:100%;
    margin-bottom:50px;
  }
`

const BgCol5 = styled(Col)`
  @media(max-width:1199px){
    :after {
      top: 435px;
      right:50px;
    }
  }
  @media(max-width:991px){
    :after {
      display:none;
    }
    :before {
      display:none;
    }
  }
  @media(max-width:767px){
    :before {
      display:none;
    }
    :after {
      display:block;
      width: 300px;
      height: 300px;
      top: -415px;
      left: 73px;
      background-position:center;
    }
  }
  @media(max-width:374px){
    :after {
      width: 280px;
    }
  }
  @media(max-width:359px){
    :after {
      width: 240px;
    }
  }
`

const bulletPointsContentDefault = [
  "Inclusion of male mentors into WomenWorks existing program, via Closed Group Mentorship or (limited) 1:1 Mentorship",
  "A series of webinars introducing the importance of men’s role in women professional advancement",
  "Podcast with topics related to diversity and inclusion",
  "Opportunity and Vacancy channels either on Discord for Male Mentors to support, post, and receive interesting opportunities"
];

export default function HeForSheSection6(props) {
  let bulletPoints = props.BulletPointsContent??bulletPointsContentDefault;
  let rowReverse = props.RowReverse?? false;
  return (
  <HeForSheSection6Background>
      <img alt="WomenWorks - women working together" src="/page-he-for-she-section6-img1.png" 
        css={css`
          z-index:2;
          @media(max-width:1199px) {
            display:none;
          }
          @media(min-width:1200px) {
            position: absolute;

            right: ${rowReverse? 'auto' : '0' };
            left: ${rowReverse? '0' : 'auto' };
            top:0px;
            height: 800px;
            max-width: 516px;
            object-fit: contain;
          }
          @media(min-width:1600px){
            display:none;
          }
      `}></img>
      <Container>
          <SectionItem css={css`${rowReverse? `display: flex;
            margin-bottom:0px;
          @media(max-width:991px){
            padding-top:61px;
          }
          @media(max-width:767px){
            padding-bottom: 50px;
          }
          @media(min-width:768px){
            padding-bottom: 100px;
          }
          `:`margin-top:61px;`}`}
          >
            <BgCol5></BgCol5>
            <Row>
                <Col css={css`${rowReverse? 'display: flex;flex-direction: column;' : ''}`}>
            <Row css={css`
            ${rowReverse?'justify-content:end;':''}
              @media(min-width:1200px) and (max-width:1599px){
                width: calc(${rowReverse? '670':'924'}/1440*100vw);
              }
            `}>
              <Col xs="12" sm="12" lg="12" xl={rowReverse? '12':'9'} >
                <HeForSheSection6Title>
                    WomenWorks for Companies
                </HeForSheSection6Title>
              </Col>
              <Col css={css`
              @media(min-width:1199px){
                display:none;
              }
              `}><img alt="WomenWorks - women working together" src="/page-he-for-she-section6-img1.png" 
              css={css`
                  max-width: 100%;
                  object-fit: cover;
                  height:150px;
                  width:100%;
                  object-position: 10% 10%;
                  @media(min-width:1200px){
                    display:none;
                  }
                  @media(max-width: 1199px){
                    margin-bottom:20px;
                  }

                
            `}></img></Col>
              <Col xs="12" sm="12" lg="12" xl={rowReverse? '12':'9'} css={css`margin-bottom:25px;@media(max-width:767px){margin-bottom:20px;}`}>
                <p>
                    Companies are severely missing out by not cultivating women employees to be leaders. <b>We are here to support your HR team help the company outperform through equal gender efforts.</b>
                </p>
                {
                  bulletPoints.map(i=>
                    <Row >
                    <Col xs="auto" css={css`padding-right:0;`}>
                        <FAHeart icon={faHeart} />
                    </Col>
                    <Col css={css`padding-left:7px;margin-bottom:20px;`}>
                        {i}
                    </Col>
                    </Row>
                    
                    )
                }
              </Col>
            </Row>
            <Row css={css`
                @media(min-width:1200px){
                    width: calc(540/924*100vw);
                    max-width:540px;
                }
                `}>
                <Col >
                    <Link id="WomenbizSection-GettoKnowProgram-Button" to={`/womenbiz-services/`}>
                        <ButtonFill6 variant="warning">Get to know the program</ButtonFill6>
                    </Link>
                    <Link id="WomenbizSection-MeetMentors-Button" to={`/the-ladybosses/`}>
                        <ButtonOutline6 variant="outline-warning">Meet our Mentors</ButtonOutline6>
                    </Link>
                </Col>
            </Row>
                </Col>

              <Col xl="auto" css={css`
                    margin-right: ${rowReverse? '80px' : '0px'};
                    max-width: 516px;
                    @media(max-width:1599px) {
                      display:none;
                    }
                `}>
                <img alt="WomenWorks - women working together" src="/page-he-for-she-section6-img1.png"
                  css={css`
                    @media(min-width:1600px) {
                        object-fit: contain;
                        height: 100%;
                    }
                `}></img>
                </Col>
                </Row>
          </SectionItem>
      </Container>
  </HeForSheSection6Background>
  )
}

